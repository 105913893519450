<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学校概况</el-breadcrumb-item>
      <el-breadcrumb-item>学校全景</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <div style="height: 300px;">

        <div style="display: flex;margin-top: 20px;">
          <span style="width: 100px;line-height: 40px;">外链接地址：</span>
          <el-input v-model="info.content" placeholder="请输入外链接地址" style="width: 700px;"></el-input>
          <el-button style="margin-left: 20px;" type="primary" @click="preserve" :loading="btnLoading">保 存</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import QuilleditorWidget from '../common/quill_editor_widget.vue';
export default {
  name: 'Organization',
  data() {
    return {
      btnLoading:false,
      info: {},//详情
    }
  },
  mounted () {
    this.getDetailes();
  },
  methods: {
    //获取详情
    async getDetailes(){
      const { data: res } = await this.$http.get('/schoolContent/detail', {params:{type:'2'}})
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        if(res.result){
          this.info = res.result;
        }
      }
    },
    async preserve(){
      if(this.info.content == ''){
        return this.$message.error('请输入内容')
      }
      this.btnLoading = true;
      let result = null;
      if(this.info.id) {//编辑
        result = await this.$http.put('/schoolContent/edit', {id:this.info.id,content:this.info.content,type:'2'})
      }else {//添加
        result = await this.$http.post('/schoolContent/add', {content:this.info.content,type:'2'})
      }
      let res = result.data;
      if (res.code !== 200) {
        this.btnLoading = false;
        this.$message.error(res.message)
      } else {
        this.btnLoading = false;
        this.$message.success('操作成功');
        this.getDetailes();
      }
    },
  }
}
</script>

<style scoped>
.btneditor{margin-top:16px;text-align:center;}
.btneditor .el-button{width:96px;}
</style>
